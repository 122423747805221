module.exports = {
  menu_imparables: "Imparables",
  menu_candidatura: "Candidatura",
  menu_actua: "Actúa",
  menu_programa: "Programa",
  menu_news: "Noticias",
  menu_transparency: "Transparencia",
  menu_absenteeballot: "Voto por correo",
  hero_intro: "Contigo, podremos continuar mejorando la vida de las personas.",
  hero_cta1: "Candidatura",
  hero_cta2: "Programa",
  hero_cta3: "Ayúdanos en la campaña",
  hero_cta4: "Elecciones Europeas",
  candidates_header: "Candidatura",
  candidates_vlc_desc: "Candidato a la alcaldia de València",
  candidates_alacant_desc: "Candidato a la alcaldia de Alicante",
  candidates_cast_desc: "Candidato a la alcaldia de Castelló",
  candidates_elx_desc: "Candidata a la alcaldia de Elche",
  candidates_europe_desc: "Candidato al Parlamento Europeo",
  candidates_monicaoltra_bio: "-",
  candidates_joanbaldovi_bio: "-",
  candidates_aitanamas_desc: "Candidata a Corts por Alicante",
  candidates_franferri_desc: "Candidat a Corts por València",
  candidates_vicentmarza_desc: "Candidato a Corts por Castellón",
  candidates_ignasicandela_desc: "Candidato al Congreso per Alicante",
  candidates_martasorli_desc: "Candidata al Congreso por Castellón",
  candidates_aitanamas_bio: "Nació el 2 de julio de 1990 en Crevillent (Alacant). Directora general de Transparència i Participació en la última legislatura, concejal en el Ayuntamiento de Crevillent entre 2011 y 2016 y portavoz local de Compromís per Crevillent. En 2011 lideró la candidatura de Compromís al Congreso por Alicante, convirtiéndose a sus 21 años en la cabeza de lista más joven del Estado español.Graduada en Ingeniería Civil por la Universitat d'Alacant, tiene además un máster en Dirección y gestión de eventos, comunicación y relaciones institucionales.",
  candidates_franferri_bio: "Nacido el 24 de mayo de 1984 en  Canals (València). Portavoz de Compromís en las Corts Valencianes y diputado en esta cámara desde 2011 donde ha sido portavoz adjunto y portavoz de Compromís en las comisiones de Industria y Comercio, Turismo y Nuevas Tecnologías, y Peticiones. Ingeniero técnico en Electrónica Industrial, cursó un segundo ciclo de Ingeniería en Organización Industrial y es especialista en energías renovables. Activista por los derechos LGTBI+, formó parte de la Federación Estatal de LGTB en el Consejo de la Juventud estatal y del consejo coordinador del Colectivo de Lesbianas, Gays, Transexuales y Bisexuales de La Safor.",
  candidates_vicentmarza_bio: "Nacido el 3 de marzo de 1983 en Castelló. Conseller de Educación, Investigación, Cultura y Deporte y diputado en las Corts Valencianes en la última legislatura, ha formado parte de la Junta Directiva 'd'Escola Valenciana Federació d'Associacions per la Llengua' y ha participado en el comité organizador del IV Congreso 'Escola Valenciana i Societat', en el I Congreso Internaional de Interacción Comunicativa y Enseñanza de Lenguas y colabora con la 'Enciclopèdia Catalana' elaborando las  actualización de nuevas entradas sobre el País Valencià. Licenciado en Magisterio con el máster de Pedagogía social y comunitaria: liderazgo de la transformación socioeducativa. Ha sido profesor de primaria y de inglés en los colegios Carles Salvador de Castelló de la Plana, Margarida Xirgú de Badalona y Gaetà Huguet de Castelló. También ha sido profesor de Francés en la escuela Campanar de València.",
  candidates_ignasicandela_bio: "-",
  candidates_martasorli_bio: "-",
  candidates_all: "Todos los candidatos y candidatas...",
  programa_download: "Programa completo",
  programa_europe: "Programa Europa 2019",
  programa_abridged_download: "Libro de medidas",
  actions_title: "¡Actúa!",
  actions_donate_title: "Ayúdanos a hacer la campaña",
  actions_donate_text: "Con una simple donación, ayudas a hacer a Mónica Oltra Presidenta de la Generalitat y a Joan Baldoví portavoz del primer grupo valenciano en el Congreso.",
  actions_donate_other: "Otra ...",
  actions_observers_title: "Sé garantía de democracia",
  actions_observers_text: "El 28 de abril, hazte interventor/a o apoderado/a de Compromís para garantizar que cada voto cuente.",
  actions_observers_button: "Hazte interventor/a",
  actions_cyber_title: "Ciberactivismo",
  actions_cyber_text: "Amplifica nuestro mensaje en las redes.",
  actions_events_title: "Ven a los actos",
  actions_events_text: "Llena de sonrisas todos los actos de campaña. Porque contigo, seremos #Imparables.",
  actions_events_button: "Calendario de actos",
  bcampaign_BANDERAS: "Hay políticos que se esfuerzan en que no sepas que hemos quintuplicado la inversión en servicios sociales en la Comunidad Valenciana en estos 4 años.",
  bcampaign_ESPANA: "Hay políticos que se van a esforzar en que no sepas que, a día de hoy, hay más de 60 causas abiertas de corrupción en el Partido Popular.",
  bcampaign_TRABAJO: "Hay políticos que se van a esforzar en que no sepas que, en esta legislatura, 1.600.000 ciudadanos han pagado menos IRPF gracias a las deducciones fiscales que hemos aplicado.",
  bcampaign_ADOCTRINAN: "Hay políticos que se van a esforzar en que te olvides de que, este año, el curso ha empezado con más de 14.000 plaza gratuitas en escuelas infantiles de 0 a 3 años.",
  bcampaign_button: "Dime otra",
  bcampaign_link: "Descubre más en nuestro Programa 2019",
  news_read_more: "Leer más...",
  news_header: "Noticias",
  news_more_on: "Más noticias en compromis.net",
  footer_follow: "Sigue la actividad de",
  footer_campaign: "{imparables} es una campaña de {compromis}"
};
